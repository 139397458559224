export const appConfig =
{
  apiKey: 'AIzaSyAPRCwzr3aTDjn7QfcIvcU-nas1tgzgxCA',
  authDomain: 'schooldog-i-alex-dev.firebaseapp.com',
  projectId: 'schooldog-i-alex-dev',
  storageBucket: 'schooldog-i-alex-dev.appspot.com',
  messagingSenderId: '1046093033778',
  appId: '1:1046093033778:web:97d71aa201acded20c672b',
  measurementId: 'G-638E8J7EGP',
}
