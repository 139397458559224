<template>
  <v-card v-if="observationSettings">
    <v-container>
      <v-row>
        <v-col class="pt-0 pb-0 pl-2" v-if="showLinked" cols=1>
          <v-simple-checkbox color="primary" :readonly="readonly" :disabled="readonly" :value="linked" @input="link($event)"></v-simple-checkbox>
        </v-col>
        <v-col :class="{'pt-0': true, 'pb-0': true, 'pl-2': showLinked}">
          <div class="mb-1" style="display: flex">
            <div class="text-subtitle-1 mb-1" style="flex: 1">
              {{ observationNumber ? 'Entry' : 'Observation Entry' }} <span v-if="observationNumber">#{{ observationNumber }}</span> ({{ observationTypeLabels[observation.observationType] }})
              <div class="text-caption">
                {{ observation.timestamp | formatDate("M/D/YYYY h:mma") }}
              </div>
            </div>
            <v-menu v-if="!readonly" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn @click="$emit('edit')" text>
                    <v-list-item-title>Edit entry</v-list-item-title>
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn @click="$emit('delete')" text>
                    <v-list-item-title>Delete entry</v-list-item-title>
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-if="showSchool && observation.schoolId" class="text-caption">
            <router-link :to="`/schools/${observation.schoolId}`">
              {{ getSchoolById(observation.schoolId).name }}
            </router-link>
          </div>
          <!-- <div v-if="showWalk && observation.walkId" class="text-caption">
            <router-link :to="`/walks/walk/${observation.walkId}/complete`">
              View Associated SchoolDog Walk
            </router-link>
          </div> -->
          <div v-if="observation.reportedByUserId" class="text-caption">
            <div>Recorded by <user-link :user-id="observation.reportedByUserId" /></div>
          </div>
        </v-col>
      </v-row>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Category
        </div>
        <div class="font-weight-medium">
          {{ observationCategoryById[observation.observationCategoryId].label }}
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Observation
        </div>
        <div class="font-weight-medium">
          {{ observationItemById[observation.observationItemId].label }}
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Quantity
        </div>
        <div class="font-weight-medium">
          {{ observation.quantity || '1' }}
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Location
        </div>
        <div class="font-weight-medium">
          {{ observation.location || 'N/A' }}
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Tags
        </div>
        <div class="mt-1 mb-1">
          <v-chip
            v-for="tag in observation.tags"
            :key="tag"
            class="tag"
          >
            {{tag}}
          </v-chip>
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Description
        </div>
        <div class="font-weight-medium">
          {{ observation.comment || 'N/A' }}
        </div>
      </div>
      <div
        v-if="imageUrls && imageUrls.length"
        style="display: flex; justify-content: space-between; flex-wrap: wrap"
      >
        <div
          v-for="pictureUrl in imageUrls"
          :key="pictureUrl.url"
          style="max-width: calc(50% - 8px); width: calc(50% - 8px);">
          <v-skeleton-loader
            :loading="pictureUrl.loading"
            type="image"
            class="ma-1"
          >
            <v-img
              :src="pictureUrl.url"
              class="max-width: 100%; max-height: 100%"
              @load="pictureUrl.loading = false"
              @error="pictureUrl.error = true"
            ></v-img>
          </v-skeleton-loader>
          <div v-if="pictureUrl.loading && isOffline">
            <v-icon color="error">mdi-wifi-off</v-icon>
            Pictures cannot be displayed while offline
          </div>
        </div>
      </div>
      <div v-else-if="observation.imagePaths && observation.imagePaths.length && numTimesTriedLoadingImages < 4">
        <v-skeleton-loader
          :loading="true"
          type="image"
          class="ma-1"
        ></v-skeleton-loader>
      </div>
      <div v-if="!hideTasks">
        <div v-if="observation.taskAssignUserId && observation.shouldAssignAsTask && !observation.taskId" class="text--secondary font-italic">
          1 Task will be created upon completion
        </div>
        <div v-else-if="observation.taskId && tasksById[observation.taskId]">
          <a :href="`/tasks/task/${observation.taskId}`" target="_blank">View Associated Task</a>
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import UserLink from '../common/UserLink.vue';
const { ref, getDownloadURL } = require('firebase/storage');
export default {
  components: { UserLink },
  name: 'ObservationCard',
  props: {
    observation: Object,
    observationNumber: Number,
    readonly: Boolean,
    showLinked: {
      type: Boolean,
      default: false,
    },
    linked: {
      type: Boolean,
      default: false,
    },
    hideTasks: Boolean,
    showSchool: Boolean,
    showWalk: Boolean,
  },
  data() {
    return {
      observationTypeLabels: {
        concern: 'Concern',
        praise: 'Praise',
        other: 'Other',
      },
      imageUrls: [],
      numTimesTriedLoadingImages: 0,
    };
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'observationSettings',
      'tasksById',
    ]),
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    observationCategoryById() {
      const map = {};
      this.observationSettings.categories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationItemById() {
      const map = {};
      this.observationSettings.items.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
  },
  methods: {
    link(event) {
      this.$emit('link', event)
    },
    async setImages () {
      this.numTimesTriedLoadingImages++
      if (this.numTimesTriedLoadingImages > 3) {
        return
      }
      if (this.observation.imagePaths && this.observation.imagePaths.length) {
        try {
          const imageUrls = await Promise.all(this.observation.imagePaths.map(async (imagePath) => {
            const imageRef = ref(this.storage, imagePath);
            const url = await getDownloadURL(imageRef);
            return {
              url,
              error: false,
              loading: false,
            };
          }));
          this.imageUrls = imageUrls.filter(url => url);
        } catch (e) {
          if (this.numTimesTriedLoadingImages < 5) {
            setTimeout(() => {
              this.setImages()
            }, this.numTimesTriedLoadingImages * 2000)
          }
        }
      } else {
        this.imageUrls = [];
      }
    },
  },
  watch: {
    'observation.imagePaths': {
      handler () {
        this.numTimesTriedLoadingImages = 0;
        this.setImages();
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.menu-item-name {
  font-weight: bold;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.tag {
  margin: 3px;
}
</style>
